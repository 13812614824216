import DataTable from 'react-data-table-component';
import { LiaSortSolid } from "react-icons/lia";
import { IoSettingsOutline, IoCloseCircle } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { FormParserNew } from '../Components/FormParser/FormParserNew';
import Masters from '../Components/masters.json';
import axiosConfig from '../Service/axiosConfig';
import { PopupModal } from '../Components';
const HomeHealthDashboard = () => {
    const sortIcon = <LiaSortSolid />;
    const [filter, setFilter] = useState("Today's");
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("Add New");
    const [actionsOpen, setActionsOpen] = useState(null);
    const [mastersModal, setMastersModal] = useState(Masters.AddLead);
    const [editable, setEditable] = useState({
        leads: false,
        patient: false,
        bill: false,
        payment: false,
        assignment: false
    })
    const [leads, setLeads] = useState([]);
    const [leadData, setLeadData] = useState({});
    const [patientData, setPatientData] = useState({});
    const [popupType, setpopupType] = useState("");
    const [message, setMessage] = useState("");
    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
                backgroundColor: '#ffffff',
                // backgroundColor: '#e7e7e7',
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
        head: {
            style: {
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                // backgroundColor: '#e7e7e7',
                backgroundColor: '#ffffff',
            },
        },
        headCells: {
            style: {
                backgroundColor: '#e7e7e7',
                backgroundColor: '#ffffff',
                color: '#000',
                fontWeight: '700',
            }
        }
    };

    const columns = [
        {
            name: 'Sno.',
            selector: (row, i) => i + 1,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.created_date,
            sortable: true,
        },
        {
            name: 'Mobile no',
            selector: row => row.mobileno,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.user?.first_name,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.city?.city_name,
            sortable: true,
        },
        {
            name: 'Service',
            selector: row => row.category?.name,
            sortable: true,
        },
        {
            name: 'Source',
            selector: row => row.source?.source,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: (row) => {
                const handleToggle = (id) => {
                    setActionsOpen(prevId => (prevId === id ? null : id));
                };
                return (
                    <div className="actions">
                        <Dropdown drop="start" className="custom-dropdown" onToggle={() => handleToggle(row.id)}>
                            <Dropdown.Toggle
                                as="div"
                                bsPrefix="custom-toggle"
                            >
                                <IoSettingsOutline style={{ cursor: 'pointer' }} color={actionsOpen === row.id ? '#408083' : ''} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom-dropdown-menu">
                                <Dropdown.Item className="custom-dropdown-item close" onClick={handleClose}>
                                    <IoCloseCircle color='#408083' />
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="custom-dropdown-item" onClick={() => handleModalShow('actions', true, row)}>Call Now</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="custom-dropdown-item" onClick={() => handleModalShow('actions', true)}>Add Details</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="custom-dropdown-item" onClick={() => handleModalShow('actions', true)}>Assign</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="custom-dropdown-item" onClick={() => handleModalShow('actions', true)}>Add Payment</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="custom-dropdown-item" onClick={() => handleModalShow('actions', true)}>Renew</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="custom-dropdown-item" onClick={() => handleModalShow('actions', true)}>Add Status</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className="custom-dropdown-item" onClick={() => handleModalShow('actions', true)}>Communications</Dropdown.Item>
                                <Dropdown.Divider />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            }
        },
    ];

    const GetLeads = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/leads/`);
            setLeads(response.data.results)
        } catch (error) {
            console.log("Error getting leads : ", error)
        }
    }

    const handleClose = (event) => {
        const dropdownElement = event.target.closest('.dropdown');
        if (dropdownElement) {
            const toggleElement = dropdownElement.querySelector('[data-bs-toggle="dropdown"]');
            if (toggleElement) {
                toggleElement.click();
            }
        }
    };

    const handleFilter = (e) => {
        setFilter(e.target.innerText)
    }

    const handleModalShow = (modalName, visible, data) => {
        if (modalName === 'addNew') {
            setModalTitle("Add New")
            setShowModal(visible)
        } if (modalName === 'actions') {
            GetEditData(data);
            setModalTitle("Add Details")
            setShowModal(visible)
        }
    }

    const handleModalClose = () => {
        setShowModal(false)
        setEditable({ leads: false, patient: false, bill: false, payment: false, assignment: false })
    }

    const submitLead = async (editData) => {
        try {
            const response = await axiosConfig.patch(`/accounts/leads/${editData.id}/`, editData);
            setEditable(prev => ({ ...prev, leads: false }));
            setpopupType("success");
            setMessage("Lead Edited Successfully");
        } catch (error) {
            setpopupType("error");
            console.log("Error editing lead : ", error)
        }
    }

    const submitPatient = async (patientData) => {
        try {
            if (patientData.id) {
                const response = await axiosConfig.patch(`/accounts/patient-details/${patientData.id}/`, {
                    ...patientData,
                    lead: patientData.lead.id,
                });
            } else {
                const response = await axiosConfig.post(`/accounts/patient-details/`, {
                    ...patientData,
                    ...leadData,
                    lead: localStorage.getItem("userId"),
                    mobileno: localStorage.getItem("mobileno")
                });
            }
            setEditable(prev => ({ ...prev, patient: false }));
            setpopupType("success");
            setMessage("Patient Edited Successfully");
        } catch (error) {
            console.log("Error editing patient : ", error)
            setpopupType("error");
        }
    }

    const submitBill = () => {
        setEditable(prev => ({ ...prev, bill: false }))
    }

    const submitPayment = () => {
        setEditable(prev => ({ ...prev, payment: false }))
    }

    const submitAssignment = () => {
        setEditable(prev => ({ ...prev, assignment: false }))
    }

    const addLead = async (leadData) => {
        try {
            let user = localStorage.getItem("userId");
            const response = await axiosConfig.post("/accounts/leads/", { ...leadData, lead: user });
            console.log("lead added", response);
            setShowModal(false);
            setpopupType("success");
            setMessage("Lead Added Successfully");
            GetLeads();
        } catch (error) {
            setpopupType("error");
            console.log("Error adding lead : ", error)
        }
    }

    const GetEditData = async (data) => {
        try {
            const lead_response = await axiosConfig.get(`/accounts/leads/${data?.id}/`);
            const patient_response = await axiosConfig.get(`/accounts/patient-details/?lead=${data?.id}`);
            if (patient_response.data.results.length > 0) {
                let patientData = {
                    ...patient_response.data.results[0],
                    service_type: patient_response.data.results[0].service_type.id
                };
                setPatientData(patientData);
            }
            let leadData = {
                ...lead_response.data,
                user: lead_response.data.user.id,
                category: lead_response.data.category.id,
                state: lead_response.data.state.id,
                city: lead_response.data.city.id,
                source: lead_response.data.source.id,
                first_name: lead_response.data.user.first_name,
                email: lead_response.data.user.email
            };
            setLeadData(leadData)
        } catch (error) {
            console.log("Error getting edit data : ", error)
        }
    }

    useEffect(() => {
        GetLeads();
    }, [])

    return (
        <>
            <div className="cards-container">
                <span className="card">
                    <div>+ 25% Last Week</div>
                    <div className="card-content">
                        <h2>32</h2>
                        <p>Today's Leads</p>
                    </div>
                </span>
                <span className="card">
                    <div>+ 25% Last Week</div>
                    <div className="card-content">
                        <h2>32</h2>
                        <p>Today's Leads</p>
                    </div>
                </span>
                <span className="card">
                    <div>+ 25% Last Week</div>
                    <div className="card-content">
                        <h2>32</h2>
                        <p>Today's Leads</p>
                    </div>
                </span>
                <span className="card">
                    <div>+ 25% Last Week</div>
                    <div className="card-content">
                        <h2>32</h2>
                        <p>Today's Leads</p>
                    </div>
                </span>
            </div>
            <div className="filters">
                <div className="filter">
                    <span className={`period ${filter === `Today's` ? 'active' : ''}`} onClick={handleFilter}>Today's</span>
                    <span className={`period ${filter === 'Last 7 Days' ? 'active' : ''}`} onClick={handleFilter}>Last 7 Days</span>
                    <span className={`period ${filter === 'Last 30 Days' ? 'active' : ''}`} onClick={handleFilter}>Last 30 Days</span>
                    <span className={`period ${filter === 'Last 1 Year' ? 'active' : ''}`} onClick={handleFilter}>Last 1 Year</span>
                    <span className={`period ${filter === 'Select Period' ? 'active' : ''}`} onClick={handleFilter}>Select Period</span>
                </div>
                <div className="search">
                    <input type="text" placeholder="Search" />
                    <IoIosSearch />
                </div>
                <button className="btn btn-add-new" onClick={() => handleModalShow('addNew', true, null)}>+ Add New</button>
            </div>
            <Modal
                show={showModal}
                onHide={() => handleModalShow('addNew', false, null)}
                dialogClassName="add-new-modal"
                aria-labelledby="custom-add-new"
            >
                <Modal.Header>
                    <Modal.Title>
                        <div className="rowBetweenCenter">
                            <span>{modalTitle}</span>
                            <IoCloseCircle color='#408083' onClick={handleModalClose} />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    id="custom-add-new"
                >
                    <div className="new-form">
                        {modalTitle === "Add New" ?
                            <FormParserNew modelObject={mastersModal} button="submit-new" buttonTitle={"Add Now"} formSubmit={addLead} /> :
                            <>
                                <p className="form-title">Lead Details</p>
                                <div className="rowBetweenCenter">
                                    <div className="w-95">
                                        <FormParserNew modelObject={Masters.AddLead} button="submit-new" readOnly={!editable.leads} formSubmit={submitLead} formData={leadData} />
                                    </div>
                                    {!editable.leads && <FaEdit onClick={() => setEditable({ ...editable, leads: !editable.leads })} />}
                                </div>
                                <p className="form-title">Patient Details</p>
                                <div className="rowBetweenCenter">
                                    <div className="w-95">
                                        <FormParserNew modelObject={Masters.PatientDetails} button="submit-new" readOnly={!editable.patient} formData={patientData} formSubmit={submitPatient} />
                                    </div>
                                    {!editable.patient && <FaEdit onClick={() => setEditable({ ...editable, patient: !editable.patient })} />}
                                </div>
                                <p className="form-title">Billing & Estimations</p>
                                <div className="rowBetweenCenter">
                                    <div className="w-95">
                                        <FormParserNew modelObject={Masters.Billing} button="submit-new" readOnly={!editable.bill} buttonTitle={"Estimate"} formSubmit={submitBill} />
                                    </div>
                                    {!editable.bill && <FaEdit onClick={() => setEditable({ ...editable, bill: !editable.bill })} />}
                                </div>
                                <p className="form-title">Payment Details</p>
                                <div className="rowBetweenCenter">
                                    <div className="w-95">
                                        <FormParserNew modelObject={Masters.PaymentDetails} button="submit-new" readOnly={!editable.payment} buttonTitle={"Add Now"} formSubmit={submitPayment} />
                                    </div>
                                    {!editable.payment && <FaEdit onClick={() => setEditable({ ...editable, payment: !editable.payment })} />}
                                </div>
                                <p className="form-title">Assign</p>
                                <div className="rowBetweenCenter">
                                    <div className="w-95">
                                        <FormParserNew modelObject={Masters.Assignment} button="submit-new" readOnly={!editable.assignment} buttonTitle={"Assign Now"} formSubmit={submitAssignment} />
                                    </div>
                                    {!editable.assignment && <FaEdit onClick={() => setEditable({ ...editable, assignment: !editable.assignment })} />}
                                </div>
                            </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <div className="tablescroll-clientbill-parent">
                <div className="tablescroll-clientbill">
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={leads}
                            sortIcon={sortIcon}
                            customStyles={customStyles}
                            fixedHeader={true}
                            highlightOnHover
                        // pagination
                        // paginationPerPage={5}
                        // paginationRowsPerPageOptions={[20, 50, 100, 200]}
                        // paginationComponentOptions={paginationComponentOptions}
                        // onSort={handleSort}
                        // paginationTotalRows={totalRows}
                        // onChangeRowsPerPage={handlePerRowsChange}
                        />
                    </div>
                </div>
            </div>
            {popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Message={message} />
            }
        </>
    )
}

export { HomeHealthDashboard }