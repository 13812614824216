import React, { useEffect, useState, createContext, useContext } from "react";
import { AiFillSetting, AiOutlineQuestionCircle, AiOutlineSetting } from 'react-icons/ai';
import { HiUserGroup } from 'react-icons/hi';
import { RiBillFill, RiStethoscopeLine, RiTeamLine } from 'react-icons/ri';
import { MdWork } from 'react-icons/md';
import { MdLibraryBooks } from "react-icons/md"
import { HiDocumentReport } from 'react-icons/hi';
import { MdCircleNotifications, MdSearch, MdOutlineChat, MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import { RiLogoutCircleRLine } from "react-icons/ri"
import { GoBell } from 'react-icons/go';
import { GrSort, GrGroup } from "react-icons/gr";
import { CgMenuGridR } from "react-icons/cg"
import "./DashboardLayout.css"
import sidebar from "./sidebar";
import { ChildNav } from "./ChildNav";
import { Outlet, useNavigate } from "react-router";
import { BsPersonWorkspace } from "react-icons/bs"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SidePanelDetail } from "../SidePanel/SidePanelDetail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import websocketUrl from "../../Service/webSocket";
const WebSocketContext = createContext();
export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
const DashboardLayout = ({ children, setSideOpen }) => {
    let user_id = JSON.parse(localStorage.getItem("userId"))
    const Navigate = useNavigate();
    const [smShow, setSmShow] = useState(false);
    const [childData, setChildData] = useState([])
    const [Toggle, SeToggle] = useState(true)
    const [activeItem, setActiveItem] = useState(null);
    const [sidePanel, setSidePanel] = useState()
    const [ParentNav, SetParentNav] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    const [caseId, setCaseId] = useState();
    const [userId, setUserId] = useState('')
    const [moduleAccess, SetModuleAccess] = useState([])
    const handleNav = (event) => {
        let parentnav = event.currentTarget.dataset.parentnav
        SetParentNav(parentnav)
        localStorage.setItem('ParentNav', parentnav);
        setChildData(sidebar[parentnav])
    }
    const AlertLogout = () => {
        setSmShow(!smShow)
    }
    const handleLogout = (event) => {
        localStorage.removeItem('token');
        setSmShow(false)
        Navigate("/")
    }
    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        let moduledata = []
        data.forEach(pms => {
            if (!moduledata.includes(pms.modulescreen.module_code)) {
                moduledata.push(pms.modulescreen.module_code)
            }
        });
        SetModuleAccess(moduledata)
    }
    console.log(moduleAccess)
    const getParentNav = async () => {
        let parentnav = localStorage.getItem('ParentNav')
        let childnavpath = localStorage.getItem('ChildNavs')
        let sidePanel = JSON.parse(localStorage.getItem('sidePanel'))
        let active_item = localStorage.getItem('activeSideData')
        setActiveItem(activeItem)
        console.log(active_item, "active item...")
        setSidePanel(sidePanel)
        if (parentnav) {
            SetParentNav(parentnav)
            SetChildNavs(childnavpath)
            setChildData(sidebar[parentnav])
        } else {
            setChildData(sidebar["worklists"]);
            SetChildNavs("/dashboard/Worklist/cases")
            localStorage.setItem('ParentNav', "worklists");
            localStorage.setItem("ChildNavs", "/dashboard/Worklist/cases")
        }
    }
    const childNavClick = (childnavpath) => {
        localStorage.setItem('ChildNavs', childnavpath);
        SetChildNavs(childnavpath)
    }
    const handleItemClick = (itemName) => {
        if (localStorage.getItem('activeSideData') === itemName) {
            setActiveItem(null);
            localStorage.setItem('activeSideData', null)
        } else {
            setActiveItem(itemName);
            localStorage.setItem('activeSideData', itemName)
            setSideOpen && setSideOpen(true)
        }
    };
    const handleSidepanel = () => {
        const newSidePanelValue = !sidePanel;
        localStorage.setItem("sidePanel", JSON.stringify(newSidePanelValue));
        setSidePanel(newSidePanelValue);
    };
    const [lastNotifiedMessage, setLastNotifiedMessage] = useState(null);

    const [websocket, setWebsocket] = useState(null);
    const handleWebsocket = () => {
        console.log("websocket", websocketUrl)
        const socket = new WebSocket(`${websocketUrl}/ws/user-socket/?token=${localStorage.getItem("token")}`);
        socket.addEventListener('open', (event) => {
            console.log('WebSocket connection opened:', event, socket);
            setWebsocket(socket)
        });
        socket.addEventListener('close', (event) => {
            console.log('WebSocket connection closed:', event);
            setWebsocket(null);
        });
        socket.addEventListener('message', (event) => {
            const parsedData = JSON.parse(event.data);

            if (parsedData.case_id !== lastNotifiedMessage) {
                setUserId(parsedData.sender_user.id);
                setCaseId(parsedData.case_id);

                toast.info(parsedData.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    onClick: () => handleToastClick(parsedData.case_id),
                });

                setLastNotifiedMessage(parsedData.case_id);
            }
        });
        socket.addEventListener('error', (event) => {
            console.error('WebSocket error:', event);
        });
        return () => {
            if (websocket) {
                websocket.close();
            }
        };
    }
    const handleToastClick = (caseId) => {
        console.log(caseId, "caseid")
        Navigate(`/dashboard/worklist/cases/${caseId}/?messages=new`);
    };
    useEffect(() => {
        getParentNav();
        // PermitData();
        handleWebsocket()

    }, [])

    return (
        <>
            <div className="d-flex">
                <div className="dashboard-layout">
                    <header>
                        {/* <div className="Dashboard-brand">
                            <img className="DashBoard-toggle" src={require("./images/Toggle.png")} alt="toggle" onClick={() => SeToggle(!Toggle)} />
                        </div> */}
                        <div className="dashboard-side-header">
                            <div className="DashBoard-Logo">
                                <img src={require("../StaffLogin/images/Logo.png")} alt="logo" />
                            </div>
                            <div className="search-dashborad">
                                <MdSearch className="sidebar-icon" />
                                <input className="input-control" placeholder="Search" />
                                <GrSort className="sidebar-icon" />
                            </div>
                            <div className="dashboard-after-search">
                                <div className="select-dashborad">
                                    <select>
                                        <option>Online</option>
                                        <option>Offline</option>
                                    </select>
                                </div>
                                <div className="topbar-icons">
                                    <AiOutlineQuestionCircle className="sidebar-icon" />
                                    <AiOutlineSetting className="sidebar-icon" />
                                    <CgMenuGridR className="sidebar-icon" />
                                </div>
                                <div className="account-btn">
                                    <button className="btn btn-white" onClick={AlertLogout} ><div className="button-docotr">
                                        <span className="doctor-name">{localStorage.getItem("first_name")}</span>
                                        <span className="doctor-account">0</span>
                                    </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </header>
                    <aside>
                        <nav className="sidebar-main">
                            <div onClick={handleNav} className={ParentNav == "worklists" ? "navitem active" : "navitem"} data-parentnav="worklists" data-code="ARIMC1" >
                                <BsPersonWorkspace className="sidebar-icon" />
                                <p className="sidebar-name">Work Lists</p>
                            </div>
                            <div onClick={handleNav} className={ParentNav == "accounts" ? "navitem active" : "navitem"} data-parentnav="accounts" data-code="ARIMC2" >
                                <HiUserGroup className="sidebar-icon" />
                                <p className="sidebar-name">User Accounts</p>
                            </div>
                           <div onClick={handleNav} className={ParentNav == "billing" ? "navitem active" : "navitem"} data-parentnav="billing" data-code="ARIMC3" >
                                <RiBillFill className="sidebar-icon" />
                                <p className="sidebar-name">Billing</p>
                            </div>
                            {/* <div onClick={handleNav} className={ParentNav == "finance" ? "navitem active" : "navitem"} data-parentnav="finance" data-code="ARIMC4" >
                                <MdLibraryBooks className="sidebar-icon" />
                                <p className="sidebar-name">Finance</p>
                            </div>
                             <div onClick={handleNav} className={ParentNav == "reports" ? "navitem active" : "navitem"} data-parentnav="reports" data-code="ARIMC5">
                                <HiDocumentReport className="sidebar-icon" />
                                <p className="sidebar-name">Reports</p>
                            </div> */}
                             <div onClick={handleNav} className={ParentNav == "assets" ? "navitem active" : "navitem"} data-parentnav="assets" data-code="ARIMC6" >
                                <MdWork className="sidebar-icon" />
                                <p className="sidebar-name">Assets</p>
                            </div>
                           <div onClick={handleNav} className={ParentNav == "masters" ? "navitem active" : "navitem"} data-parentnav="masters" data-code="ARIMC7">
                                <AiFillSetting className="sidebar-icon" />
                                <p className="sidebar-name">Settings</p>
                            </div>
                        </nav>
                    </aside>
                    {Toggle && <nav className="sidebar-child">
                        <div className="sidebar-child-container">
                            <ChildNav childData={childData} childNavClick={childNavClick} ChildNavs={ChildNavs} />
                        </div>
                    </nav>}
                    <div className="dashboard-content d-flex">
                        <div className={`dashboard-child ${activeItem ? 'sidepanel' : ''}`}>
                            <WebSocketContext.Provider value={websocket}>
                                <Outlet />
                            </WebSocketContext.Provider>
                        </div>
                        {JSON.parse(localStorage.getItem("sidePanel")) &&
                            <div className="sidepanel-parent">
                                <div onClick={() => handleItemClick('Radiologist')} className={`sidepanel-item ${localStorage.getItem('activeSideData') === 'Radiologist' ? 'active' : ''}`}>
                                    <RiStethoscopeLine className="sidepanel-icons" />
                                    <div>Radiologist</div>
                                </div>
                                <div onClick={() => handleItemClick('Clients')} className={`sidepanel-item ${localStorage.getItem('activeSideData') === 'Clients' ? 'active' : ''}`}>
                                    <RiTeamLine className="sidepanel-icons" />
                                    <div>Clients</div>
                                </div>
                                <div onClick={() => handleItemClick('Chat')} className={`sidepanel-item ${localStorage.getItem('activeSideData') === 'Chat' ? 'active' : ''}`}>
                                    <MdOutlineChat className="sidepanel-icons" />
                                    <div>Chat</div>
                                </div>
                                <div onClick={() => handleItemClick('Others')} className={`sidepanel-item ${localStorage.getItem('activeSideData') === 'Others' ? 'active' : ''}`}>
                                    <IoIosMore className="sidepanel-icons" />
                                    <div>Others</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="sidepanel-btn" onClick={handleSidepanel}>
                    {
                        JSON.parse(localStorage.getItem("sidePanel")) ? (
                            <MdKeyboardArrowRight size={20} />
                        ) : (
                            <MdKeyboardArrowLeft size={20} />
                        )
                    }
                </div>
                {localStorage.getItem('activeSideData') !== 'null' &&
                    <SidePanelDetail setActiveItem={setActiveItem} />
                }
                {/* <footer>
             <div>
                <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                </p>
            </div>
            </footer> */}
                <Modal
                    show={smShow}
                    onHide={() => setSmShow(false)}
                    aria-labelledby="example-modal-sizes-title-sm">
                    <Modal.Body className="success-icon text-center">
                        <h5 className="successmsg">Are you sure you want to logout ?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="primary" className="btn btn-primary" onClick={() => setSmShow(false)}>
                            Cancel
                        </button>
                        <button variant="primary" className="btn btn-primary" onClick={handleLogout}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                {user_id !== userId &&
                    <ToastContainer />
                }

            </div>
        </>
    )
}
export { DashboardLayout }
