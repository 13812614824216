import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import Modal from "react-bootstrap/Modal";
import { MdDeleteOutline, MdOutlineEdit, MdOutlineAssignment } from "react-icons/md"
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { Button, Dropdown } from 'react-bootstrap';
import { MdOutlineRefresh } from "react-icons/md"
import { CiMenuKebab } from "react-icons/ci"
import { AddStaff } from "./AddStaff";
import { ClientAdd } from "./ClientAdd";
const ClientCreateList = ({ Code }) => {
    const [RowId, SetRowId] = useState()
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();
    const [actionPermisson, SetActionPermission] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentDirection, setCurrentDirection] = useState(null);
    const [check, setCheck] = useState(false)
    const [tableId, setTableId] = useState()
    const [search, SetSearch] = useState({
        search: ""
    })
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }
    const [clientPercentages, setClientPercentages] = useState({});
    const [clientId, setClientId] = useState([])
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(
                `/client/clients/?page=${currentPage}&page_size=${RowPerPage}&ordering=${currentDirection === "asc" ? currentColumn : `-${currentColumn}`
                }`
            );
            const clients = response.data.results
            SetData(clients);
            console.log(clients)
            const percentageValue = clients.map(async (client) => {
                const percentage = await axiosConfig.get(`/client/clients/${client.id}/get_percentage/`);
                return { id: client.id, percentage: percentage.data.percentage };
            })
            const percentages = await Promise.all(percentageValue);

            const percentageMap = {};
            percentages.forEach((item) => {
                percentageMap[item.id] = item.percentage;
            });
            setClientPercentages(percentageMap);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/client/clients/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }
    const PermitData = () => {
        const Permmitdata = localStorage.getItem("permission")
        const data = JSON.parse(Permmitdata)
        const find_data = data.find(res => res.modulescreen.code === Code)
        console.log(find_data)
        SetActionPermission(find_data.modulescreen)
    }


    const handleSort = (column, direction) => {
        setCurrentColumn(column.selector);
        setCurrentDirection(direction);
    };
    const HandleMouse = (row) => {
        setTableId(row.id)
    }
    const HandleRowmouseLeave = () => {
        setTableId()
    }
    const handleRowClick = (row) => {
        console.log(row)
        navigate(`/dashboard/users/clients/${row.id}/edit`)
    }
    useEffect(() => {
        GetData();
        PermitData();
    }, [currentPage, RowPerPage, currentColumn, currentDirection]);
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
        },
        {
            name: "Id",
            selector: "id",
            cell: (row) => `${row.id}`,
            sortable: true,
            left: true,
        },
        {
            name: "Client Name",
            selector: "client_name",
            cell: (row) => `${row.client_name}`,
            sortable: true
        },
        {
            name: "Phone Number",
            selector: "phone",
            cell: (row) => `${row.phone}`,
            sortable: true
        },
        {
            name: "Email",
            selector: "email",
            cell: (row) => `${row.email}`,
            sortable: true
        },
        {
            name: "Status",
            selector: row => {
                const Assign = async () => {
                    return navigate(`/dashboard/clients/${row.id}/tariff`);
                }
                const HandleEdit = async () => {
                    return navigate(`/dashboard/users/clients/${row.id}/edit`);

                }
                const HandleView = async () => {
                    return navigate(`/dashboard/users/clients/${row.id}/edit`);

                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/client/clients/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const percentage = clientPercentages[row.id];
                const shouldShowActions = tableId === row.id
                if (shouldShowActions) {
                    return (
                        <>
                            <div className="row">
                                <div className="col-3">
                                    <MdOutlineAssignment className="dashboard-tabe-data-actions" onClick={Assign} />
                                </div>
                                {actionPermisson && actionPermisson.can_read && <div className="col-3">
                                    <AiOutlineEye className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>}
                                {actionPermisson && actionPermisson.can_edit && <div className="col-3">
                                    <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>}
                                {actionPermisson && actionPermisson.can_delete && <div className="col-3">
                                    <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>}
                            </div>
                            {
                                AlertDeletePopupType === "success" &&
                                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                            }
                        </>
                    );
                }
                else {
                    return (
                        <div className="d-flex align-items-center">
                            <div className="text-center Active_name">Active</div>
                            <div className="progress status_bar">
                                <div className="progress-bar" role="progressbar" aria-valuenow={percentage || 0} aria-valuemin="0" aria-valuemax="100" style={{ width: `${percentage || 0}%` }}>
                                    {percentage !== undefined ? `${percentage.toFixed(2)}%` : ''}
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }
    ]
    return (
        <>
            
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Clients</h5>
                    {actionPermisson && actionPermisson.can_add && <button className="btn btn-primary" onClick={handleShow}> +Add New</button>}
                    <Modal
                        size="lg"
                        show={show}
                        onHide={handleClose}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                Create Client
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ClientAdd />
                        </Modal.Body>
                    </Modal>
                </div>
                <div className="tablescroll-clientbill-parent">
                    <div className="tablescroll-clientbill">
                        <div className="table-header-menu">
                            {/* <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onClick={handleCheckboxClick}   
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                <Dropdown.Item>Action</Dropdown.Item>
                                <Dropdown.Item>Another action</Dropdown.Item>
                                <Dropdown.Item>Something else here</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                            {/* <MdOutlineRefresh className="sidebar-icon-table" />
                        <CiMenuKebab className="sidebar-icon-table" /> */}
                        </div>
                        <div className="table-responsive">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page, totalRows) => {
                                    setCurrentPage(page);
                                    GetData(currentColumn, currentDirection);
                                }}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer
                                fixedHeader
                                customStyles={tableCustomStyles}
                                fixedHeaderScrollHeight="450px"
                                // selectableRows
                                onSelectedRowsChange={({ selectedRows }) =>
                                    console.log(selectedRows)
                                }
                                selectableRowsHighlight
                                highlightOnHover
                                subHeader
                                onRowMouseEnter={HandleMouse}
                                onRowMouseLeave={HandleRowmouseLeave}
                                progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                                onRowClicked={(row) => handleRowClick(row)}
                                subHeaderComponent={<div className="form-control d-flex align-items-center w-25">
                                    <input type="text" placeholder="Search" className="input-search" name="search" value={search.search} onChange={HandleSearch} />
                                    <div className="search-icon1">
                                        <AiOutlineSearch size={24} />
                                    </div>
                                </div>
                                }>
                            </DataTable>
                        </div>
                    </div>
                </div>
                {/* {Data.length > 0 && <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>} */}
                {deletePopupType !== "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            
        </>
    )
}
export { ClientCreateList }